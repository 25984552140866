* {
    margin:0;
    padding:0;
    box-sizing:border-box;
}
a {
    text-decoration:none;
}
li {
    list-style:none;
}
img {
    border:0;
    vertical-align:bottom;
}
body {
    font-size:16px;
    font-weight:400;
}